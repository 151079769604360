import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {LayoutThemeItem} from '@thebell/common/models/layout-theme-item';
import {LayoutTheme} from '@thebell/common/models/layout-theme';
import {LayoutPreset} from '@thebell/common/models/layout-preset';
import {LayoutWidgetsService} from '@thebell/common/services/api/layout-widgets';
import {LayoutThemesService} from '@thebell/common/services/api/layout-themes';
import {DetectedRouteChangeService} from '@thebell/common/services/utils/detected-route-change';
import {SEOService} from '@thebell/common/services/others/seo';
import {HelperService} from '@thebell/common/services/utils/helper';
import {ThemeDataRequestOptions} from '@thebell/common/models/theme-data-request-options';
import {LayoutLine} from '@thebell/common/models/layout-line';
import {Widget} from '@thebell/common/models/widget';
import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';
import {ContentLoadingService} from '@thebell/common/services/others/event-bus';
import {RepeatableLinesExistsService} from '@thebell/common/services/others/repeatable-lines-exists';
import {PostMeta} from '@thebell/data-transfer-objects';
import {PostApiService} from '@thebell/frontend/api';

@Component({
  selector: 'app-widget-scroller',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './widget-scroller.component.html',
  styleUrls: ['./widget-scroller.component.scss'],
})
export class WidgetScrollerComponent implements OnDestroy, OnInit {
  @Input()
  set load(val: boolean) {
    if (val) this.customLoadEmitter.next(val);
  }
  @Input() scaleAllow? = true;
  layoutThemeItems: LayoutThemeItem[] = [];
  layoutTheme: LayoutTheme;
  layoutPreset: LayoutPreset;
  layoutData: any[] = [];
  lastPostId = null;
  requestChainParams = null;
  dataCollectorSubject: Subscription;
  detectedRouteChangeSubject: Subscription;
  excludePosts = [];
  exec = [];
  postCount = 0;
  loading = false;
  postSlug = [];
  currentRouter = '';
  tagName = '';
  childThemeRenderSubject = new Subject<any>();
  environment: Environment;
  repeatable: boolean;
  uploadedPostMeta: any = {};
  appUrl: string;
  firstPostData: any;
  currentId;
  private customLoadEmitter = new BehaviorSubject(false);
  constructor(
    private layoutWidgetsService: LayoutWidgetsService,
    private layoutThemesService: LayoutThemesService,
    private detectedRouteChangeService: DetectedRouteChangeService,
    public activatedRoute: ActivatedRoute,
    private location: Location,
    private seo: SEOService,
    private titleService: Title,
    private helperService: HelperService,
    private router: Router,
    private contentLoadingService: ContentLoadingService,
    private envService: EnvironmentService,
    private repeatableLinesExists: RepeatableLinesExistsService,
    private postService: PostApiService
  ) {}

  ngOnInit() {
    this.environment = this.envService.getEnvironment();
    this.appUrl = this.environment.appUrl;
    this.contentLoadingService.set(false);
    this.detectedRouteChangeSubject = this.detectedRouteChangeService.subject.subscribe((cr) => {
      const current_route = cr;
      this.customLoadEmitter.subscribe((load) => {
        if (load) {
          if (current_route) {
            this.currentRouter = current_route.snapshot.data.name;
            this.loading = true;
            this.layoutData = [];
            this.layoutThemeItems = [];
            this.lastPostId = null;
            this.requestChainParams = null;
            this.excludePosts = [];
            this.exec = [];
            this.postCount = 0;
            this.postSlug = [];
            this.tagName = '';
            if (this.dataCollectorSubject && !this.dataCollectorSubject.closed) {
              this.dataCollectorSubject.unsubscribe();
            }
            const initRequest = new ThemeDataRequestOptions(); // данные для первого запроса
            if (current_route.snapshot.data.name === 'tag' || current_route.snapshot.data.name === 'category') {
              if (this.currentRouter === 'main') {
                initRequest.count = 1;
              } else {
                initRequest.count = 4;
              }
            }
            this.dataCollectorSubject = this.layoutWidgetsService.subject.subscribe(([themes, themesData, presets]) => {
              this.requestChainParams = themesData.request_chain_params;
              const data = themesData.data;

              if (data.length > 0) {
                this.layoutTheme = themes.find(
                  (t) => undefined !== t.routes.find((route) => route.slug === this.currentPageSlug())
                );

                if (!this.layoutTheme) {
                  this.layoutTheme = themes.find((t) => undefined !== t.routes.find((route) => route.slug === 'main'));
                }

                this.layoutTheme.is_special_page = this.layoutTheme.is_special || false;
                if (this.layoutTheme.is_special_page) {
                  if (!this.layoutTheme.items[this.layoutTheme.items.length - 1].repeatable) {
                    const virtualThemeItem = new LayoutThemeItem();
                    virtualThemeItem.model = 'LayoutLine';
                    virtualThemeItem.repeatable = true;
                    virtualThemeItem.layout_theme_layout_theme_item_id = 999999;

                    const virtualLine = new LayoutLine();
                    virtualLine.id = 999999;
                    virtualLine.name = 'three_33';
                    virtualLine.slug = 'three_33';
                    virtualLine.sort = 999999;
                    virtualLine.description = 'virtual-line';

                    const virtualWidget = new Widget();
                    virtualWidget.id = 999999;
                    virtualWidget.name = 'b33';
                    virtualWidget.slug = 'b33';
                    virtualWidget.basis = '33';
                    virtualWidget.description = 'virtual-widget';

                    virtualLine.widgets = [virtualWidget, virtualWidget, virtualWidget];
                    virtualThemeItem.item = virtualLine;

                    this.layoutTheme.items.push(virtualThemeItem);
                  }
                }
                this.repeatableLinesExists.findRepeats(this.layoutTheme.items);
                this.layoutThemeItems = this.layoutTheme.items;
                if (this.currentPageSlug() !== 'search') {
                  this.layoutPreset = presets.find((preset) => preset.route.slug === this.currentPageSlug());
                } else {
                  this.layoutPreset = null;
                }

                try {
                  this.initPresetThemes(themes);
                  // eslint-disable-next-line no-empty
                } catch (ignore) {}

                [...data].forEach((layoutData: any[]) => {
                  const themeIndex = this.layoutData ? this.layoutData.length : 0;
                  // Skip first post render
                  if (themeIndex === 0 && current_route.snapshot.data.name === 'post') {
                    this.excludePosts.push(layoutData[0].item.id);
                    this.firstPostData = layoutData[0];
                    layoutData = layoutData.slice(1);
                    //this.addMeta(this.firstPostData.item);
                  }
                  if (themeIndex > 0 && this.layoutTheme.items.every((i) => !i.repeatable)) return;
                  const d = this.layoutThemesService.setData(this.layoutTheme, [...layoutData], presets, themeIndex);
                  if (!(Object.values(d) as Array<any>).filter((el) => el.length).length) return;
                  this.layoutData.push(d);
                  if (current_route.snapshot.data.name === 'post') {
                    this.postSlug.push(this.firstPostData);
                    layoutData.forEach((lD) => {
                      this.postSlug.push(lD);

                      if (this.exec.length === 0) {
                        lD.item.categories.forEach((category) => {
                          this.exec.push(category.id);
                        });
                      }
                      this.excludePosts.push(lD.item.id);
                    });
                    this.postCount = this.excludePosts.length;
                    // для страницы поста первый раз грузим 2 темы
                    initRequest.count = 3;
                  }
                });
                if (current_route.snapshot.data.name === 'tag') {
                  if (data.length > 0 && data[0].length > 0) {
                    const currentTag = data[0][0].item.tags.find(
                      (tagItem) => tagItem.slug === current_route.snapshot.params.tag
                    );
                    this.tagName = currentTag ? currentTag.title : '';
                  }
                }
              }
              // Calculate url for meta
              const url = this.router.url.replace(/^\//, '').split('?')[0];
              const prefix_url = this.appUrl[this.appUrl.length - 1] === '/' ? this.appUrl : `${this.appUrl}/`;
              const meta_url = prefix_url + url;
              // Set meta url
              console.log("component-widget-scroller prefix_url: ", prefix_url)
              console.log("component-widget-scroller meta_url: ", meta_url)
              this.seo.addCanonical(meta_url);
              if (current_route.snapshot.data.name === 'post' && data.length > 0 && data[0].length > 0) {
                const mainCategory =
                  data[0][0].item.categories.find((category: { is_main: any; }) => category.is_main) || data[0][0].item.categories[0];
                const categorySlug = mainCategory ? mainCategory.slug : null;
                this.seo.addAmpLink(meta_url, categorySlug);
              } else {
                this.seo.addAmpLink(meta_url, null);
              }

              this.contentLoadingService.set(true);
              this.loading = false;
            });

            let appendedObj = {
              route: current_route.snapshot.data.name,
              param: '',
              last_post_id: this.lastPostId,
              isAdmin: this.helperService.authorized() ? 1 : 0,
            };
            appendedObj = this.setAppendedObj(appendedObj);
            this.layoutWidgetsService.collectData(Object.assign(initRequest, appendedObj));
          }
        }
      });
    });
  }

  setAppendedObj(appendedObj) {
    if (
      ['tag', 'category', 'author', 'post', 'search', 'spec'].includes(this.activatedRoute.snapshot.data.name) &&
      Object.values(this.activatedRoute.snapshot.params).length > 0
    ) {
      appendedObj.param = '' + Object.values(this.activatedRoute.snapshot.params)[0];
    }

    if (this.activatedRoute.snapshot.data.name === 'post') {
      appendedObj.excludePosts = this.excludePosts;
      appendedObj.exec = this.exec;
      appendedObj.postCount = this.postCount;
    }

    return appendedObj;
  }

  ngOnDestroy(): void {
    if (this.currentRouter === 'post') {
      this.titleService.setTitle('The Bell — деньги, бизнес, власть');
    }
    this.detectedRouteChangeSubject?.unsubscribe();
    this.dataCollectorSubject?.unsubscribe();
    this.layoutWidgetsService?.unsubscribe();
  }

  scroll(count) {
    if (this.currentRouter === 'main') {
      count = 1
    }
    // Отключаем бесконечный скролл на главной
    //if (this.currentRouter !== 'main') {
      if (!this.loading && this.repeatableLinesExists.getValue()) {
        this.loading = true;
        let appendedObj = {
          route: this.activatedRoute.snapshot.data.name,
          param: '',
          last_post_id: this.lastPostId,
          request_chain_params: JSON.stringify(this.requestChainParams),
          index: this.layoutData ? this.layoutData.length + 1 : 1,
          count,
          isAdmin: this.helperService.authorized() ? 1 : 0,
        };

        appendedObj = this.setAppendedObj(appendedObj);

        let event = `load_${appendedObj.route}`;
        if (appendedObj.param && appendedObj.route !== 'post') event += `_${appendedObj.param}`;
        ga('send', 'event', 'infinity_scroll', event);
        dataLayer.push({
          'event': 'event', 'eventCategory': 'infinity_scroll', 'eventAction': event
        });
        this.layoutWidgetsService.collectData(Object.assign(new ThemeDataRequestOptions(), appendedObj));
      }
    // } else {
    //   this.loading = false;
    // }
  }

  addMeta(item) {
    const putMeta = (meta: PostMeta) => {
      this.seo.push([
        {name: 'description', content: meta.description},
        {property: 'og:locale', content: 'ru_RU'},
        {property: 'og:type', content: 'article'},
        {property: 'og:title', content: meta.title},
        {property: 'og:description', content: meta.description},
        {property: 'og:url', content: `${this.environment.baseUrl}${item.slug}`},
        {property: 'og:site_name', content: 'The Bell'},
        {property: 'article:publisher', content: 'https://www.facebook.com//'},
        {property: 'article:section', content: 'Новости'},
        {property: 'article:published_time', content: item.publish_date},
        {property: 'article:modified_time', content: item.modified_date},
        {property: 'og:image', content: meta.image},
        {property: 'og:image:secure_url', content: meta.description},
        {property: 'og:image:width', content: '1200'},
        {property: 'og:image:height', content: '630'},
        {property: 'twitter:card', content: 'summary_large_image'},
        {property: 'twitter:description', content: meta.description},
        {property: 'twitter:title', content: meta.title},
        {property: 'twitter:site', content: '@thebell_io'},
        {property: 'twitter:image', content: meta.image},
        {property: 'twitter:creator', content: '@thebell_io'},
      ]);
    };

    const postMeta = this.uploadedPostMeta[item.id];
    if (!postMeta) {
      this.postService.meta(item.id).subscribe((meta: PostMeta) => {
        this.uploadedPostMeta[item.id] = meta;
        putMeta(meta);
      });
    } else {
      putMeta(postMeta);
    }
  }

  stripHtml(html) {
    const ogDescriptionLength = 391;
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    let content = tmp.textContent || tmp.innerText || '';
    content = content.split(/\r?\n|\r/)[0];
    if (content.length > ogDescriptionLength) {
      content = content.substring(0, ogDescriptionLength);
    }
    return content + ' ...';
  }

  private initPresetThemes(themes) {
    const presetThemes = this.layoutPreset.items.filter((elem) => elem.fixed_model_name === 'App\\Models\\LayoutTheme');

    if (presetThemes.length) {
      const basicThemeItems = this.layoutTheme.items;
      const initializedThemeItems = [];

      const layoutLineIds = [];

      basicThemeItems.forEach((themeItem) => {
        layoutLineIds.push(themeItem.layout_theme_layout_theme_item_id);

        const themesInLine = presetThemes.filter(
          (presetTheme) => presetTheme.layout_theme_layout_theme_item_id === themeItem.layout_theme_layout_theme_item_id
        );

        if (!themesInLine.length) {
          initializedThemeItems.push(themeItem);
          return;
        }

        const themesContainer = JSON.parse(JSON.stringify(themeItem));
        themesContainer.model = 'LayoutThemes';
        themesContainer.item = [];
        themesContainer.weight = 'w';

        themesInLine.forEach((themeInLine) => {
          const theme = themes.find((elem) => elem.id === themeInLine.fixed_model_id);

          theme.is_special_page = false;
          if (theme.items[theme.items.length - 1].layout_theme_layout_theme_item_id === 999999) {
            theme.items.pop();
          }

          const presetTheme = JSON.parse(JSON.stringify(theme));

          presetTheme.model = 'LayoutTheme';

          const fakeId = themeItem.layout_theme_layout_theme_item_id * 10000 + themesContainer.item.length;
          presetTheme.layout_theme_layout_theme_item_id = fakeId;

          const basis = (themeItem.item as LayoutLine).widgets[themeInLine.position - 1].basis;
          const position = themeInLine.position;

          (presetTheme as LayoutTheme).position = position;
          (presetTheme as LayoutTheme).basis = +basis;

          themesContainer.weight += '-' + basis;

          themesContainer.item.push(presetTheme);
        });

        initializedThemeItems.push(themesContainer);
      });

      if (initializedThemeItems) this.layoutTheme.items = initializedThemeItems;
    }
  }

  private currentPageSlug() {
    return this.activatedRoute.snapshot.data.name !== 'spec'
      ? this.activatedRoute.snapshot.data.name
      : Object.values(this.activatedRoute.snapshot.params)[0];
  }

  hasSubtitle(): boolean {
    return this.layoutTheme.style?.subtitle?.length > 0;
  }

  getSubtitle(): string {
    return this.layoutTheme.style?.subtitle;
  }

  hasLead(): boolean {
    return this.layoutTheme.style?.lead?.length > 0;
  }

  getCategory(): string {
    return this.layoutTheme.style?.is_story ? 'сюжет' : 'спецпроект';
  }

  getLead(): string {
    return this.layoutTheme.style?.lead;
  }

  hasLogo(): boolean {
    return this.layoutTheme.style?.logo?.src?.length > 0;
  }

  getLinkLogo(): string {
    return this.layoutTheme.style?.linkLogo;
  }

  getLogo(): string {
    return this.layoutTheme.style?.logo?.src;
  }

  getLogoClass(): string {
    return this.layoutTheme.style?.logoSize;
  }
}
