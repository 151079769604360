<div class="menu category_menu">
    <div class="menu_col">
        <div class="menu_item"><a routerLink="" (click)="linkClick.emit()">Главное</a></div>
        <div class="menu_item"><a routerLink="/category/news" (click)="linkClick.emit()">Новости</a></div>
        <div class="menu_item"><a routerLink="/tag/ekonomika" (click)="linkClick.emit()">Экономика</a></div>
        <div class="menu_item"><a routerLink="/tag/finansy" (click)="linkClick.emit()">Финансы</a></div>
        <div class="menu_item"><a routerLink="/tag/internet" (click)="linkClick.emit()">Технологии</a></div>
        <div class="menu_item"><a routerLink="/category/bell-investitsii" (click)="linkClick.emit()">Инвестиции</a></div>
        <div class="menu_item" style="margin-bottom: 0;">
            <a routerLink="/tag/biznes" (click)="linkClick.emit()">Бизнес</a>
        </div>
    </div>
</div>



<div class="menu" [class.header]="position === 'header'">
    <div class="menu_col">
        <div class="menu_title">Проекты</div>
        <div class="menu_item">
            <a href="/newsletters" target="_blank">
                Email-рассылки</a
            >
        </div>
        <div class="menu_item">
            <a href="https://www.youtube.com/channel/UCo3S9OQDrM79i7yPmixnSsQ" target="_blank">
                YouTube:&nbsp;«Это&nbsp;Осетинская»</a
            >
        </div>
        <div class="menu_item"><a href="https://thebell.club/" target="_blank">Bell.Club</a></div>
        <div class="menu_item">
            <a href="https://www.youtube.com/channel/UC7_VjEuPizTvqvPWr8NLnTA" target="_blank"
                >YouTube:&nbsp;Bell.Club&nbsp;Live
            </a>
        </div>
        <div class="menu_item">
            <a href="/professional" target="_blank">Bell.Professional</a>
        </div>
        <div class="menu_item">
            <a href="https://inschool.ai/" target="_blank">IN SCHOOL</a>
        </div>
    </div>
    <div class="menu_col">
        <div class="menu_title">РАССЫЛКИ</div>
        <div class="menu_item"><a href="http://eepurl.com/dB0DFr" target="_blank">Утренняя</a></div>
        <div class="menu_item"><a href="http://eepurl.com/dB0DFn" target="_blank">Вечерняя</a></div>
        <div class="menu_item"><a href="http://eepurl.com/dB0DFf" target="_blank">Итоги&nbsp;недели</a></div>
        <div class="menu_item"><a href="http://eepurl.com/dB0DFb" target="_blank">Технорассылка</a></div>
        <div class="menu_item"><a href="/invest" target="_blank">Bell.Инвестиции</a></div>
        <div class="menu_item">
            <a href="http://eepurl.com/h6mgLj" target="_blank">The&nbsp;Bell&nbsp;in&nbsp;English</a>
        </div>
    </div>
    <div class="menu_col">
        <div class="menu_title">СОЦСЕТИ</div>
        <!-- <div class="menu_item"><a href="https://www.facebook.com//" target="_blank">Facebook</a></div> -->
        <div class="menu_item"><a href="https://vk.com/thebell_io" target="_blank">ВКонтакте</a></div>
        <div class="menu_item"><a href="https://ok.ru/thebell" target="_blank">Одноклассники</a></div>
        <!-- <div class="menu_item"><a href="https://www.instagram.com/thebell_io/" target="_blank">Instagram</a></div> -->
    </div>
    <div class="menu_col">
        <div class="menu_title">TELEGRAM</div>
        <div class="menu_item"><a href="https://t.me/thebell_io" target="_blank">The&nbsp;Bell</a></div>
<!--        <div class="menu_item"><a href="https://t.me/Bell_daily" target="_blank">Что&nbsp;мне&nbsp;с&nbsp;этого?</a></div>-->
        <div class="menu_item"><a href="https://t.me/Bell_tech" target="_blank">The&nbsp;Bell&nbsp;Tech</a></div>
    </div>
    <div class="menu_col">
        <div class="menu_title">TWITTER</div>
        <div class="menu_item"><a href="https://twitter.com/ru_thebell" target="_blank">The&nbsp;Bell</a></div>
        <div class="menu_item">
            <a href="https://twitter.com/thebell_io" target="_blank">The&nbsp;Bell&nbsp;in&nbsp;English</a>
        </div>
    </div>
    <div class="menu_col about-us">
        <div class="menu_title">О НАС</div>
      <div class="menu_item"><a href="/together">Стать патроном</a></div>
      <div class="menu_item"><a routerLink="/about">О проекте</a></div>
      <div class="menu_item"><a routerLink="/team">Команда и контакты</a></div>
      <div class="menu_item"><a routerLink="/privacy-policy">Конфиденциальность</a></div>
      <div class="menu_item"><a href="#" (click)="openConsentPreferences($event)">Настройки cookie</a></div>
      <div class="menu_item"><a href="https://drive.google.com/file/d/1iZPp8aB0SRN_B4uixMi-LFvBxM4DHuit/view" target="_blank">Оферта</a></div>
    </div>
    <div class="menu_col wrap">
        <div class="menu_title">КОММЕРЦИЯ</div>
        <div class="menu_item">
            <a routerLink="/category/partnery-the-bell">Партнеры</a>
        </div>
      <div class="menu_item">
        <a href="/assets/docs/the_bell_mediakit.pdf" target="_blank">Рекламодателям</a>
      </div>
    </div>
  <ng-content></ng-content>
</div>
